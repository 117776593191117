<template>
  <v-container>


  </v-container>
</template>

<script>
export default {
  components: {
  },

  data: () => ({
    loading: false,
  }),

  // ライフサイクルフック
  created: function () {

  },

  mounted: async function () {

  },

  methods: {

  },

  watch: {

  },
};
</script>

<style scoped>

</style>
